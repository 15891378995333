var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Schedule Change Admin")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.refreshData()}}},on),[_c('v-icon',[_vm._v("fal fa-sync")])],1)]}}])},[_c('span',[_vm._v("Refresh the data")])]),(!_vm.isSmall)?_c('v-text-field',{ref:"searchField",staticClass:"mr-1",staticStyle:{"max-width":"160px"},attrs:{"label":"Name/Banner ID","clear-icon":"fal fa-times","dense":"","outlined":"","hide-details":"","clearable":""},on:{"blur":_vm.searchBlur},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e(),(_vm.isRecords && !_vm.isSmall)?[_c('v-select',{staticClass:"mr-1",staticStyle:{"max-width":"150px"},attrs:{"items":_vm.actions,"label":"Action Taken","dense":"","hide-details":"","outlined":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-action',[_c('v-avatar',{attrs:{"color":item.value === 'add' ? 'success white--text' : (item.value === 'drop' ? 'error white--text' : 'info white--text'),"size":"32"}},[_vm._v(_vm._s(item.count))])],1)]}}],null,false,1585110358),model:{value:(_vm.actionFilter),callback:function ($$v) {_vm.actionFilter=$$v},expression:"actionFilter"}}),(_vm.approverFilterOptions.length > 0)?_c('v-autocomplete',{staticClass:"mr-1",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.approverFilterOptions,"label":"Approver Search","dense":"","outlined":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({attrs:{"color":item.count > 10 ? 'red' : (item.count > 5 ? 'orange' : 'green white--text'),"size":"32"}},on),[_vm._v(_vm._s(item.count))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.count)+" Student"+_vm._s(item.count === 1 ? '' : 's')+" to Approve")])])],1)]}}],null,false,1190132935),model:{value:(_vm.approverFilter),callback:function ($$v) {_vm.approverFilter=$$v},expression:"approverFilter"}}):_vm._e()]:(_vm.statusCountItems.length > 1)?_c('v-select',{staticClass:"mr-1",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.statusCountItems,"label":"Status Filter","dense":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),(item.count > 0)?_c('v-avatar',{attrs:{"color":"info white--text","size":"32"}},[_vm._v(_vm._s(item.count))]):_vm._e()]}}]),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}):_vm._e(),(!_vm.isSmall)?_c('v-select',{style:('max-width:' + (_vm.isRecords ? '120' : '180') + 'px'),attrs:{"items":_vm.terms,"label":"Term","dense":"","hide-details":"","outlined":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}}):_vm._e()],2),(_vm.isSmall)?_c('v-toolbar',[_c('v-text-field',{ref:"searchField",staticClass:"mr-1",staticStyle:{"max-width":"160px"},attrs:{"label":"Name/Banner ID","clear-icon":"fal fa-times","dense":"","outlined":"","hide-details":"","clearable":""},on:{"blur":_vm.searchBlur},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-select',{staticClass:"mr-1",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.statusCountItems,"label":"Status Filter","dense":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),(item.count > 0)?_c('v-avatar',{attrs:{"color":"info white--text","size":"32"}},[_vm._v(_vm._s(item.count))]):_vm._e()]}}],null,false,444133813),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}),(_vm.isRecords)?_c('v-select',{staticClass:"mr-1",staticStyle:{"max-width":"150px"},attrs:{"items":_vm.actions,"label":"Action Taken","dense":"","hide-details":"","outlined":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-action',[_c('v-avatar',{attrs:{"color":item.value === 'add' ? 'success white--text' : (item.value === 'drop' ? 'error white--text' : 'info white--text'),"size":"32"}},[_vm._v(_vm._s(item.count))])],1)]}}],null,false,1585110358),model:{value:(_vm.actionFilter),callback:function ($$v) {_vm.actionFilter=$$v},expression:"actionFilter"}}):_vm._e(),(_vm.isRecords && _vm.approverFilterOptions.length > 0)?_c('v-autocomplete',{staticClass:"mr-1",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.approverFilterOptions,"label":"Approver Filter","dense":"","outlined":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({attrs:{"color":item.count > 10 ? 'red' : (item.count > 5 ? 'orange' : 'green white--text'),"size":"32"}},on),[_vm._v(_vm._s(item.count))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.count)+" Student"+_vm._s(item.count === 1 ? '' : 's')+" to Approve")])])],1)]}}],null,false,1190132935),model:{value:(_vm.approverFilter),callback:function ($$v) {_vm.approverFilter=$$v},expression:"approverFilter"}}):_vm._e(),_c('v-select',{style:('max-width:' + (_vm.isRecords ? '120' : '180') + 'px'),attrs:{"items":_vm.terms,"label":"Term","dense":"","hide-details":"","outlined":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1):(_vm.isRecords)?_c('v-toolbar',{attrs:{"flat":""}},[_vm._l((_vm.statusCounts),function(ref,index){
var code = ref.code;
var text = ref.text;
var count = ref.count;
return [(index > 0)?[_c('v-spacer',{key:code + '-sp1'}),_c('v-icon',{key:code + '-icon'},[_vm._v("fal fa-arrow-alt-right")]),_c('v-spacer',{key:code + '-sp2'})]:_vm._e(),_c('v-btn',{key:code,attrs:{"disabled":count === 0,"color":_vm.statusFilter === code ? 'blue darken-4' : '',"dark":_vm.statusFilter === code,"outlined":count > 0 && _vm.statusFilter !== code && _vm.isDark},on:{"click":function($event){_vm.statusFilter = code}}},[(count > 0)?_c('v-badge',{attrs:{"content":count}},[_vm._v(_vm._s(text))]):_c('span',[_vm._v(_vm._s(text))])],1)]})],2):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.changes,"headers":_vm.headers,"options":_vm.options,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20, 25] }},on:{"update:options":_vm.updateOptions,"dblclick:row":function (x, ref) {
	var _id = ref.item._id;

	return _vm.$router.push('/student/schedule/' + _id);
}},scopedSlots:_vm._u([{key:"item.submitDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.submitDate)))]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":'/student/schedule/' + item._id}},[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.statusCounts.find(function (ref) {
	var code = ref.code;

	return code === item.status;
}).text || item.status))]}},{key:"item.course",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.action === 'add' ? 'success' : (item.action === 'drop' ? 'error' : 'info'),"label":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fas fa-"+_vm._s(item.action === 'add' ? 'plus' : (item.action === 'drop' ? 'minus' : 'info'))+"-circle")]),_c('span',{style:('color:' + (_vm.isDark ? 'rgb(255,255,255)' : 'rgba(0,0,0,.87)'))},[_vm._v(_vm._s(_vm.isSmall ? item.course.substring(0, item.course.indexOf(' - ')) : item.course))])],1)]}},{key:"item.approvals",fn:function(ref){
var item = ref.item;
return [_vm._l((item.approvals),function(ref){
var category = ref.category;
var date = ref.date;
return [(category !== 'Records')?_c('v-tooltip',{key:category,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":date ? 'success' : 'error',"label":"","outlined":""}},on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fas fa-"+_vm._s(date ? 'check' : 'times')+"-circle")]),_c('span',{style:('color:' + (_vm.isDark ? 'rgb(255,255,255)' : 'rgba(0,0,0,.87)'))},[_vm._v(_vm._s(category.substring(0, 3)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(category)+" "+_vm._s(date ? 'Approved' : 'Approval Pending'))])]):_vm._e()]})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }