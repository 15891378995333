<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Schedule Change Admin</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="refreshData()">
            <v-icon>fal fa-sync</v-icon>
          </v-btn>
        </template>
        <span>Refresh the data</span>
      </v-tooltip>
      <v-text-field v-if="!isSmall" ref="searchField" v-model="searchText" label="Name/Banner ID" clear-icon="fal fa-times" class="mr-1" dense outlined hide-details clearable style="max-width:160px" @blur="searchBlur"></v-text-field>
      <template v-if="isRecords && !isSmall">
        <v-select v-model="actionFilter" :items="actions" label="Action Taken" class="mr-1" dense hide-details outlined clearable style="max-width:150px">
          <template v-slot:item="{ item }">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
            <v-list-item-action>
              <v-avatar :color="item.value === 'add' ? 'success white--text' : (item.value === 'drop' ? 'error white--text' : 'info white--text')" size="32">{{ item.count }}</v-avatar>
            </v-list-item-action>
          </template>
        </v-select>
        <v-autocomplete v-if="approverFilterOptions.length > 0" v-model="approverFilter" :items="approverFilterOptions" label="Approver Search" class="mr-1" dense outlined clearable hide-details style="max-width:200px">
          <template v-slot:item="{ item }">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on" :color="item.count > 10 ? 'red' : (item.count > 5 ? 'orange' : 'green white--text')" size="32">{{ item.count }}</v-avatar>
                </template>
                <span>{{ item.count }} Student{{ item.count === 1 ? '' : 's' }} to Approve</span>
              </v-tooltip>
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </template>
      <v-select v-else-if="statusCountItems.length > 1" v-model="statusFilter" :items="statusCountItems" label="Status Filter" class="mr-1" dense hide-details outlined style="max-width:200px">
        <template v-slot:item="{ item }">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-avatar v-if="item.count > 0" color="info white--text" size="32">{{ item.count }}</v-avatar>
        </template>
      </v-select>
      <v-select v-if="!isSmall" v-model="term" :items="terms" label="Term" dense hide-details outlined :style="'max-width:' + (isRecords ? '120' : '180') + 'px'"></v-select>
    </v-toolbar>
    <v-toolbar v-if="isSmall">
      <v-text-field ref="searchField" v-model="searchText" label="Name/Banner ID" clear-icon="fal fa-times" class="mr-1" dense outlined hide-details clearable style="max-width:160px" @blur="searchBlur"></v-text-field>
      <v-select v-model="statusFilter" :items="statusCountItems" label="Status Filter" class="mr-1" dense hide-details outlined style="max-width:200px">
        <template v-slot:item="{ item }">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-avatar v-if="item.count > 0" color="info white--text" size="32">{{ item.count }}</v-avatar>
        </template>
      </v-select>
      <v-select v-if="isRecords" v-model="actionFilter" :items="actions" label="Action Taken" class="mr-1" dense hide-details outlined clearable style="max-width:150px">
        <template v-slot:item="{ item }">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action>
            <v-avatar :color="item.value === 'add' ? 'success white--text' : (item.value === 'drop' ? 'error white--text' : 'info white--text')" size="32">{{ item.count }}</v-avatar>
          </v-list-item-action>
        </template>
      </v-select>
      <v-autocomplete v-if="isRecords && approverFilterOptions.length > 0" v-model="approverFilter" :items="approverFilterOptions" label="Approver Filter" class="mr-1" dense outlined clearable hide-details style="max-width:200px">
        <template v-slot:item="{ item }">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-avatar v-on="on" :color="item.count > 10 ? 'red' : (item.count > 5 ? 'orange' : 'green white--text')" size="32">{{ item.count }}</v-avatar>
              </template>
              <span>{{ item.count }} Student{{ item.count === 1 ? '' : 's' }} to Approve</span>
            </v-tooltip>
          </v-list-item-action>
        </template>
      </v-autocomplete>
      <v-select v-model="term" :items="terms" label="Term" dense hide-details outlined :style="'max-width:' + (isRecords ? '120' : '180') + 'px'"></v-select>
    </v-toolbar>
    <v-toolbar v-else-if="isRecords" flat>
      <template v-for="({ code, text, count }, index) of statusCounts">
        <template v-if="index > 0">
          <v-spacer :key="code + '-sp1'"></v-spacer>
          <v-icon :key="code + '-icon'">fal fa-arrow-alt-right</v-icon>
          <v-spacer :key="code + '-sp2'"></v-spacer>
        </template>
        <!-- eslint-disable vue/no-v-for-template-key-on-child -->
        <v-btn :key="code" :disabled="count === 0" :color="statusFilter === code ? 'blue darken-4' : ''" :dark="statusFilter === code" :outlined="count > 0 && statusFilter !== code && isDark" @click="statusFilter = code">
          <v-badge v-if="count > 0" :content="count">{{ text }}</v-badge>
          <span v-else>{{ text }}</span>
        </v-btn>
      </template>
    </v-toolbar>
    <v-data-table :items="changes" :headers="headers" :options="options" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }" @update:options="updateOptions" @dblclick:row="(x, { item: { _id } }) => $router.push('/student/schedule/' + _id)">
      <template v-slot:item.submitDate="{ item }">{{ stringFormatDate(item.submitDate) }}</template>
      <template v-slot:item.view="{ item }">
        <v-btn icon :to="'/student/schedule/' + item._id">
          <v-icon>fal fa-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.status="{ item }">{{ statusCounts.find(({ code }) => code === item.status).text || item.status }}</template>
      <template v-slot:item.course="{ item }">
        <v-chip :color="item.action === 'add' ? 'success' : (item.action === 'drop' ? 'error' : 'info')" label outlined>
          <v-icon left small>fas fa-{{ item.action === 'add' ? 'plus' : (item.action === 'drop' ? 'minus' : 'info') }}-circle</v-icon>
          <span :style="'color:' + (isDark ? 'rgb(255,255,255)' : 'rgba(0,0,0,.87)')">{{ isSmall ? item.course.substring(0, item.course.indexOf(' - ')) : item.course }}</span>
        </v-chip>
      </template>
      <template v-slot:item.approvals="{ item }">
        <template v-for="{ category, date } in item.approvals">
          <v-tooltip v-if="category !== 'Records'" :key="category" top>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" :color="date ? 'success' : 'error'" label outlined>
                <v-icon left small>fas fa-{{ date ? 'check' : 'times' }}-circle</v-icon>
                <span :style="'color:' + (isDark ? 'rgb(255,255,255)' : 'rgba(0,0,0,.87)')">{{ category.substring(0, 3) }}</span>
              </v-chip>
            </template>
            <span>{{ category }} {{ date ? 'Approved' : 'Approval Pending'}}</span>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  setup (props, { root }) {
    const isSmall = computed(() => ['xs', 'sm'].includes(root.$vuetify.breakpoint.name))
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isDark = computed(() => 'settings' in user.value && 'darkMode' in user.value.settings ? user.value.settings.darkMode : false)
    const isRecords = computed(() => user.value.roles.includes('Records') || user.value.roles.includes('Technology Services'))
    const changes = ref([])
    const actions = ref([
      { value: 'add', text: 'Added Course', count: 0, disabled: true },
      { value: 'drop', text: 'Dropped Course', count: 0, disabled: true },
      { value: 'change', text: 'Changed Grade Mode', count: 0, disabled: true }
    ])
    const statusCounts = ref([
      { code: 'submitted', text: 'Initial Review', count: 0, disabled: true },
      { code: 'pending', text: 'Pending Approval', count: 0, disabled: true },
      { code: 'approved', text: 'Ready to Process', count: 0, disabled: true },
      { code: 'returned', text: 'Returned', count: 0, disabled: true },
      { code: 'completed', text: 'Completed', count: 0, disabled: true },
      { code: 'cancelled', text: 'Cancelled', count: 0, disabled: true }
    ])
    const statusCountItems = computed(() => statusCounts.value.filter(({ code, count }) => isRecords.value || (!['submitted', 'returned'].includes(code) && count > 0)).map(({ code: value, text, disabled }) => {
      if (!isRecords.value && value === 'approved') text = 'Approved'
      return { value, text, disabled }
    }))
    const headers = computed(() => {
      const arr = [
        { text: 'Banner ID', value: 'bannerId' },
        { text: 'Name', value: 'name' },
        { text: 'Submitted Date', value: 'submitDate' },
        { text: 'Course', value: 'course' }
      ]
      if (isRecords.value) {
        arr.push({ text: 'Status', value: 'status', sort: false })
        if (statusFilter.value === 'pending') arr.push({ text: 'Approvals', value: 'approvals', sort: false })
      }
      arr.push({ text: 'View', value: 'view', sort: false })
      return arr
    })
    const options = computed(() => root.$store.state.student.scheduleAdmin.options || { page: 1, itemsPerPage: 10, sortBy: [], sortDesc: [] })
    const approverFilter = ref()
    const approverFilterOptions = ref([])
    const actionFilter = ref(null)

    function updateOptions (opt) {
      root.$store.commit('student/setScheduleAdminFields', { options: opt })
      // loadData()
    }

    const terms = ref([])
    const term = computed({
      get: () => root.$store.state.student.scheduleAdmin.term,
      set: (term) => root.$store.commit('student/setScheduleAdminFields', { term })
    })

    onMounted(async () => {
      // Get possible terms from the term warehouse
      const aggregate = [
        { $group: { _id: '$term', count: { $sum: 1 } } },
        { $project: { term: '$_id', count: 1 } },
        { $sort: { term: -1 } }
      ]
      const { data } = await root.$feathers.service('student/schedule-change').find({ query: { aggregate } })
      if (isRecords.value) {
        terms.value = data.map(({ term }) => term)
        term.value = terms.value[0]
      } else {
        // Look up the nice names for the terms
        const { data: termData } = await root.$feathers.service('system/term').find({ query: { term: { $in: data.map(({ term }) => term) }, $sort: { term: -1 } } })
        terms.value = termData.map(({ label: text, term: value }) => { return { text, value } })
        term.value = terms.value[0].value
      }
      if (searchText.value !== '' && searchText.value != null) searchActive.value = true
      if (statusFilter.value === '' && !isRecords.value) {
        statusFilter.value = 'pending'
      }
      if (isRecords.value && window.innerWidth < 1323) {
        root.$store.commit('setSideNavActive', false)
      }
      loadData()
    })

    const searchActive = ref(false)
    const searchField = ref(null)
    const searchText = computed({
      get: () => root.$store.state.student.scheduleAdmin.searchText || '',
      set: (val) => root.$store.commit('student/setScheduleAdminFields', { searchText: val || '' })
    })

    function activateSearch () {
      searchActive.value = true
      root.$nextTick(() => searchField.value.focus())
    }

    function searchBlur () {
      if (searchText.value === '' || searchText.value == null) {
        searchActive.value = false
      }
    }

    const statusFilter = computed({
      get: () => root.$store.state.student.scheduleAdmin.filter,
      set: (val) => root.$store.commit('student/setScheduleAdminFields', { filter: val })
    })

    const isLoading = ref(false)
    watch([searchText, actionFilter, statusFilter, term], () => {
      loadData()
      loadActionCounts()
    })
    watch(term, () => {
      loadApprovers()
      loadStatusCounts()
    })
    watch(approverFilter, async () => {
      await loadStatusCounts()
      loadData()
      loadActionCounts()
    })

    function refreshData () {
      loadData()
      loadActionCounts()
      loadApprovers()
      loadStatusCounts()
    }

    async function loadData () {
      if (isLoading.value || term.value === '') return
      changes.value = []
      isLoading.value = true
      const $match = { term: term.value }
      if (searchText.value !== '' && searchText.value != null) {
        if (searchText.value.substr(0, 1) === '@') {
          $match.bannerId = { $regex: searchText.value }
        } else {
          $match.name = { $regex: searchText.value, $options: 'i' }
        }
      }
      const $filters = { 'changes.status': statusFilter.value }
      if (isRecords.value) {
        if (approverFilter.value !== '' && approverFilter.value != null) {
          $filters['changes.approvals.pidm'] = approverFilter.value
        }
      } else {
        const $elemMatch = { pidm: user.value.pidm }
        if (statusFilter.value === 'pending') $elemMatch.date = { $exists: false }
        else if (statusFilter.value === 'approved') {
          $filters['changes.status'] = { $in: ['pending', 'approved'] }
          $elemMatch.date = { $exists: true }
        }
        $filters['changes.approvals'] = { $elemMatch }
      }
      if (actionFilter.value) {
        $filters['changes.action'] = actionFilter.value
      }
      const aggregate = [{ $match }]
      if (['cancelled', 'completed'].includes(statusFilter.value)) {
        // Merge the changes and completed arrays
        aggregate.push({ $addFields: { 'completed.status': { $cond: { if: { $eq: ['$completed.cancelled', true] }, then: 'cancelled', else: 'completed' } } } })
        aggregate.push({ $project: { term: 1, bannerId: 1, name: 1, changes: { $concatArrays: [{ $ifNull: ['$changes', []] }, { $ifNull: ['$completed', []] }] } } })
      }
      aggregate.push({ $unwind: '$changes' })
      aggregate.push({ $match: $filters })
      aggregate.push({ $lookup: {
        from: 'Calendar-Classes',
        localField: 'changes.crn',
        foreignField: 'crn',
        as: 'course',
        let: { term: '$term' },
        pipeline: [
          { $match: { $expr: { $eq: ['$term', '$$term'] } } },
          { $project: {
            title: 1,
            instructor: { $first: '$instructors' },
            meetingBase: 1
          } },
          { $project: {
            title: 1,
            instructor: '$instructor.name',
            meets: {
              $reduce: {
                input: '$meetingBase',
                initialValue: '',
                in: {
                  $concat: [
                    '$$value',
                    { $cond: [{ $eq: ['$$value', ''] }, '', '<br/>'] },
                    { $cond: [{ $eq: ['$$this.days', ''] }, '', { $concat: ['$$this.days', ' '] }] },
                    { $cond: [{ $eq: ['$$this.startTime', ''] }, '', { $concat: ['$$this.startTime', ' - '] }] },
                    '$$this.endTime',
                    { $cond: [
                      { $eq: ['$$this.room', null] },
                      '',
                      { $concat: [' in ', '$$this.room', ' (', '$$this.building', ')'] }
                    ] }
                  ]
                }
              }
            }
          } }
        ]
      } })
      aggregate.push({ $project: {
        bannerId: 1,
        name: 1,
        submitDate: '$changes.submitted',
        status: '$changes.status',
        action: '$changes.action',
        change: '$changes',
        approvals: '$changes.approvals',
        course: { $first: '$course' } }
      })
      aggregate.push({ $project: {
        bannerId: 1,
        name: 1,
        submitDate: 1,
        status: 1,
        action: 1,
        change: 1,
        approvals: 1,
        course: '$course.title'
      } })
      // console.log(JSON.stringify(aggregate))
      const { data } = await root.$feathers.service('student/schedule-change').find({ query: { aggregate } })
      changes.value = data
      isLoading.value = false
    }

    /**
     * Loads the action counts (i.e. how many adds/drops/changes are requested for the selected status and approver)
     */
    async function loadActionCounts () {
      const $match = { term: term.value }
      if (searchText.value !== '' && searchText.value != null) {
        if (searchText.value.substr(0, 1) === '@') {
          $match.bannerId = { $regex: searchText.value }
        } else {
          $match.name = { $regex: searchText.value, $options: 'i' }
        }
      }
      const $filters = { 'changes.status': 'pending', 'changes.approvals': { $elemMatch: { pidm: user.value.pidm, date: { $exists: false } } } }
      if (isRecords.value) {
        if (statusFilter.value !== 'all') $filters['changes.status'] = statusFilter.value
        else delete $filters['changes.status']
        if (approverFilter.value !== '' && approverFilter.value != null) {
          $filters['changes.approvals'] = { $elemMatch: { pidm: approverFilter.value } }
        } else {
          delete $filters['changes.approvals']
        }
      }
      const aggregate = [
        { $match },
        { $addFields: { 'completed.status': { $cond: { if: '$completed.cancelled', then: 'cancelled', else: 'completed' } } } },
        { $project: {
          term: 1,
          bannerId: 1,
          name: 1,
          changes: { $concatArrays: [{ $ifNull: ['$changes', []] }, { $ifNull: ['$completed', []] }] }
        } },
        { $unwind: '$changes' }
      ]
      if (JSON.stringify($filters) !== '{}') aggregate.push({ $match: $filters })
      aggregate.push({ $group: { _id: '$changes.action', count: { $sum: 1 } } })
      const { data } = await root.$feathers.service('student/schedule-change').find({ query: { aggregate } })
      for (let i = 0; i < actions.value.length; i++) {
        const rec = data.find(({ _id }) => actions.value[i].value === _id)
        actions.value.splice(i, 1, { ...actions.value[i], count: rec ? rec.count : 0, disabled: rec == null })
      }
    }

    /**
     * Loads the counts for each possible status
     * Has to conver the "completed" array to combine it back with the changes array in order for the counts to work correctly
     */
    async function loadStatusCounts () {
      if (term.value == null || term.value === '') return
      const aggregate = [
        { $match: { term: term.value } },
        { $addFields: { 'completed.status': { $cond: { if: { $eq: ['$completed.cancelled', true] }, then: 'cancelled', else: 'completed' } } } },
        { $project: {
          term: 1,
          bannerId: 1,
          name: 1,
          changes: { $concatArrays: [{ $ifNull: ['$changes', []] }, { $ifNull: ['$completed', []] }] }
        } },
        { $unwind: '$changes' }
      ]
      if (!isRecords.value || approverFilter.value) {
        aggregate.push({ $match: { 'changes.approvals.pidm': approverFilter.value || user.value.pidm } })
      }
      aggregate.push({ $group: { _id: '$changes.status', count: { $sum: 1 } } })
      // console.log(JSON.stringify(aggregate))
      const { data: aggData } = await root.$feathers.service('student/schedule-change').find({ query: { aggregate } })
      for (let i = 0; i < statusCounts.value.length; i++) {
        const rec = aggData.find(({ _id }) => statusCounts.value[i].code === _id)
        statusCounts.value.splice(i, 1, { ...statusCounts.value[i], count: rec ? rec.count : 0, disabled: rec == null })
      }
      const hash = {}
      statusCounts.value.forEach(({ code, count }) => { hash[code] = count })
      if (isRecords.value && (statusFilter.value === '' || (statusFilter.value !== 'all' && hash[statusFilter.value] === 0))) {
        const priority = ['submitted', 'approved', 'pending']
        for (const code of priority) {
          if (code in hash && hash[code] > 0) {
            statusFilter.value = code
            break
          }
        }
      }
    }

    /**
     * Loads the approvers and approver counts (only if the user has the Records or Technology Services roles)
     * This gets a list of all of the assigned approvers (skipping Records), and counts how many pending approvals they have
     * Note that this aggregation loads all approvers, not just those with pending approvals, but then counts the number of pending approvals (also counts the total entries, but that is not currently used anywhere)
     */
    async function loadApprovers () {
      if (term.value === '' || !isRecords.value) return
      const aggregate = [
        { $match: { term: term.value } },
        { $addFields: { 'completed.status': { $cond: { if: '$completed.cancelled', then: 'cancelled', else: 'completed' } } } },
        { $project: {
          term: 1,
          bannerId: 1,
          name: 1,
          changes: { $concatArrays: [{ $ifNull: ['$changes', []] }, { $ifNull: ['$completed', []] }] }
        } },
        { $unwind: '$changes' },
        { $project: { approvals: '$changes.approvals', status: '$changes.status' } },
        { $unwind: '$approvals' },
        { $match: { 'approvals.pidm': { $ne: null }, 'approvals.category': { $ne: 'Records' } } },
        { $lookup: {
          from: 'Directory',
          localField: 'approvals.pidm',
          foreignField: 'pidm',
          as: 'person'
        } },
        { $project: { bannerId: 1, pers: { $first: '$person' }, date: { $ifNull: ['$approvals.date', false] }, status: 1 } },
        { $project: { bannerId: 1, pidm: '$pers.pidm', name: { $concat: ['$pers.name.last', ', ', '$pers.name.first'] }, pending: { $and: [{ $eq: ['$date', false] }, { $eq: ['$status', 'pending'] }] } } },
        { $group: {
          _id: { pidm: '$pidm', name: '$name' },
          total: { $sum: 1 },
          pending: { $sum: { $cond: { if: { $eq: ['$pending', true] }, then: 1, else: 0 } } }
        } },
        { $sort: { '_id.name': 1 } }
      ]
      // console.log(JSON.stringify(aggregate))
      const { data } = await root.$feathers.service('student/schedule-change').find({ query: { aggregate } })
      approverFilterOptions.value = data.map(({ _id: { pidm, name }, pending: count }) => { return { text: name, value: pidm, count } })
    }

    function getStatus (entry) {
      switch (entry.status) {
        case 'submitted': return 'Initial Review'
        case 'returned': return 'Returned to Student'
        case 'pending': return 'Pending Approval'
        case 'approved': return 'Ready to Process'
        case 'processed': return 'Processed'
      }
    }

    return {
      isSmall,
      user,
      isDark,
      isRecords,
      changes,
      actions,
      headers,
      statusCounts,
      statusCountItems,
      statusFilter,
      options,
      approverFilter,
      approverFilterOptions,
      actionFilter,
      updateOptions,
      terms,
      term,
      stringFormatDate,
      searchActive,
      searchField,
      searchText,
      activateSearch,
      searchBlur,
      refreshData,
      loadData,
      getStatus
    }
  }
}
</script>
